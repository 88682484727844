<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async } as vm">
      <div class="grid-container">
        <div class="grid-item">
          <img src="../../assets/icons/goat-sports-logo.png" [ngClass]="isMobile ? 'small-logo' : 'big-logo'" />
        </div>
        <div class="grid-item">
          <div [ngClass]="isMobile ? 'container' : 'container fullHeight'">
            <mat-card-content>
              <div>
                <mat-icon [ngClass]="
                    isMobile
                      ? 'material-icons material-icons-outlined icon-mobile'
                      : 'material-icons material-icons-outlined icon-full'
                  ">
                  mail</mat-icon>
                <b>
                  {{ t('Check your email.') }}
                </b>
              </div>

              <p>
                {{
                t(
                'We have sent a password recovery, instruction to your email.'
                )
                }}
              </p>
            </mat-card-content>
          </div>
        </div>
        <div class="grid-item">
          <p class="text" *ngIf="isMobile">
            {{
            t(
            'Didn’t receive the email? Check your spam folder, or try another email address'
            )
            }}.
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>