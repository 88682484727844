import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { tap } from 'rxjs';

@Component({
  selector: 'goatsports-mail-check',
  templateUrl: './mail-check.component.html',
  styleUrls: ['./mail-check.component.scss'],
})
export class MailCheckComponent {
  isMobile = this.deviceService.isMobile();
  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.translationsService.changeLanguage(language);
    })
  );

  constructor(
    private translationsService: TranslationsService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute
  ) {}
}
