<main>
  <ng-container *ngIf="language$ | async">
    <ng-container *transloco="let t">
      <div class="container">
        <div style="height: 4px">
          <mat-progress-bar
            *ngIf="isWaiting"
            mode="indeterminate"
          ></mat-progress-bar>
        </div>
        <section>
          <header>
            <img
              src="../../assets/icons/goat-sports-logo.png"
              width="100px"
              height="44px"
            />
          </header>
          <div class="welcome-message">
            <b>{{ t('Welcome back to GOAT Sports') }}!</b>
            <!-- <p>Please log in to continue using the app.</p> -->
          </div>

          <form [formGroup]="form">
            <mat-label
              >{{ t('Email') }} <span class="required"> *</span></mat-label
            >

            <mat-form-field>
              <input
                matInput
                autocapitalize="none"
                formControlName="email"
                type="email"
                [placeholder]="t('mail@example.com')"
              />
            </mat-form-field>
            <mat-label
              >{{ t('Password') }} <span class="required"> *</span></mat-label
            >

            <mat-form-field>
              <input
                matInput
                [type]="isPasswordVisible ? 'text' : 'password'"
                autocomplete="on"
                formControlName="password"
                [placeholder]="t('Enter your password')"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="isPasswordVisible = !isPasswordVisible"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="isPasswordVisible"
              >
                <mat-icon>{{
                  isPasswordVisible ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
            </mat-form-field>

            <goatsports-error-messages
              *ngIf="
                (form.controls['email'].hasError('required') &&
                  showValidation) ||
                (form.controls['password'].hasError('required') &&
                  showValidation)
              "
            ></goatsports-error-messages>
            <div
              [ngClass]="
                (form.controls['email'].hasError('required') &&
                  showValidation) ||
                (form.controls['password'].hasError('required') &&
                  showValidation)
                  ? 'forgot-button '
                  : 'forgot-button margin-bottom'
              "
            >
              <button
                mat-button
                (click)="redirectForgotPassword()"
                color="primary"
              >
                {{ t('Forgot Password') }}?
              </button>
            </div>
            <div
              *ngIf="
                (form.controls['email'].hasError('required') &&
                  showValidation) ||
                (form.controls['password'].hasError('required') &&
                  showValidation)
              "
              [ngClass]="
                (form.controls['email'].hasError('required') &&
                  showValidation) ||
                (form.controls['password'].hasError('required') &&
                  showValidation)
                  ? 'error-wrapper-message margin-bottom'
                  : 'error-wrapper-message'
              "
            >
              <mat-error>
                {{ t('Wrong password or email, please try again!') }}
              </mat-error>

              <mat-error
                *ngIf="
                  form.controls['password'].hasError('pattern') &&
                  showValidation
                "
              >
                {{
                  t(
                    'Password needs to have at least one lower case letter, one upper case letter, one number and one special character '
                  ) + '~!@#$%^&*_-+=`|\\(){}[]:;"<>,.?/'
                }}
              </mat-error>
            </div>

            <button
              class="log-btn"
              mat-flat-button
              (click)="submit()"
              [disabled]="isWaiting"
            >
              {{ t('Log In') }}
            </button>
          </form>

          <div class="create-account-wrapper">
            <p>
              {{ t('Don’t have an account') }}?
              <span class="create-acc" (click)="navigateToCreateAcc()">{{
                t('Create Account') | uppercase
              }}</span>
            </p>
          </div>
        </section>
      </div>
    </ng-container>
  </ng-container>
</main>
