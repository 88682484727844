<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async }">
      <div class="grid-container">
        <div class="grid-item">
          <img
            src="../../assets/icons/goat-sports-logo.png"
            [ngClass]="isMobile ? 'small-logo' : 'big-logo'"
          />
        </div>
        <div class="grid-item">
          <div [ngClass]="isMobile ? 'container' : 'container fullHeight'">
            <mat-card-content>
              <div>
                <img
                  [ngClass]="isMobile ? 'checkmark-icon' : 'checkmark-big-icon'"
                  src="../../assets/icons/checkmark-circle-outline.svg"
                />

                <b>
                  {{ t('You have accepted the invite!') }}
                </b>
              </div>

              <p>
                {{ t('Switch to the app to') }}
                <u class="cursor-pointer">{{ t('LOG IN') }}</u
                >.
              </p>

              <div class="desktop-icons-and-text" *ngIf="!isMobile">
                <p>{{ t('Don’t have the app? Get it in the store') }}.</p>
                <div class="scan-icons">
                  <!-- QR code  -->
                  <img
                    style="cursor: pointer"
                    height="38px"
                    src="../../assets/icons/app-store-icon.svg"
                    alt=""
                    (click)="navigateToStore('https://goatsports.app/appstore')"
                  />
                  <img
                    style="cursor: pointer"
                    height="38px"
                    src="../../assets/icons/google-play-icon.svg"
                    alt=""
                    (click)="
                      navigateToStore('https://goatsports.app/googleplay')
                    "
                  />
                </div>
              </div>
            </mat-card-content>
          </div>
        </div>
        <div class="grid-item footer-image" *ngIf="isMobile">
          <div>
            <p>{{ t('Don’t have the app? Download it here') }}.</p>
            <div>
              <img
                height="38px"
                src="../../assets/icons/app-store-icon.svg"
                alt=""
                (click)="navigateToStore('https://goatsports.app/appstore')"
              />
              <img
                height="38px"
                src="../../assets/icons/google-play-icon.svg"
                alt=""
                (click)="navigateToStore('https://goatsports.app/googleplay')"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>
