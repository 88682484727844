import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailConfirmationService } from '@goatsports/authority/data-access';
import { switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'goatsports-accepted-email',
  templateUrl: './accepted-email.component.html',
  styleUrls: ['./accepted-email.component.scss'],
})
export class AcceptedEmailComponent {
  title!: string;
  detail!: string;
  returnUrl!: string;
  isMobile = this.deviceService.isMobile();
  language!: string;
  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      this.returnUrl = params.get('returnUrl') ?? '';
      this.title = params.get('title') ?? '';
      this.detail = params.get('detail') ?? '';

      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.language = language;
      this.translationsService.changeLanguage(language);
    }),
    switchMap((params) => {
      return this.emailConfirmationService.emailConfirmationPut({
        email: params.get('email') ?? '',
        token: params.get('token') ?? '',
      });
    })
  );

  constructor(
    private route: ActivatedRoute,
    private emailConfirmationService: EmailConfirmationService,
    private deviceService: DeviceDetectorService,
    private translationsService: TranslationsService
  ) {}

  redirectToLogin() {
    if (this.deviceService.isMobile()) {
      location.href = `goatsports://email-confirmed?language=${this.language}`;
    } else {
      location.href = environment.coachPortalUrl;
    }
  }

  navigateToStore(route: string) {
    location.href = route;
  }
}
