export class RouteConstants {
  static readonly SIGN_OUT_ROUTE = 'sign-out';
  static readonly SIGN_IN_ROUTE = 'signin';
  static readonly CONFIRM_EMAIL_ROUTE = 'confirm-email';
  static readonly FORGOT_PASSWORD_ROUTE = 'forgot-password';
  static readonly RESET_PASSWORD_ROUTE = 'reset-password';
  static readonly CHANGE_PASSWORD_ROUTE = 'change-password';
  static readonly MAIL_CHECK_ROUTE = 'email-check';
  static readonly PASSWORD_RECOVERY_ROUTE = 'password-recovery';
  static readonly PASSWORD_CONFIRM_ROUTE = 'password-confirmed';
}
