<div class="main">
  <ng-container *ngIf="language$ | async">
    <ng-container *transloco="let t">
      <div class="grid-container">
        <div class="grid-item">
          <img
            src="../../assets/icons/goat-sports-logo.png"
            [ngClass]="isMobile ? 'small-logo' : 'big-logo'"
          />
        </div>
        <div class="grid-item error-content">
          <div>
            <div [ngClass]="isMobile ? 'container' : 'container fullHeight'">
              <mat-card-content>
                <div>
                  <img
                    *ngIf="status === invitationStatus.Canceled"
                    [ngClass]="isMobile ? 'small-icon' : 'big-icon'"
                    src="../../assets/icons/close-icon.svg"
                  />

                  <img
                    *ngIf="status !== invitationStatus.Canceled"
                    [ngClass]="isMobile ? 'small-icon' : 'big-icon'"
                    src="../../assets/icons/hourglass-outline.svg"
                  />

                  <b>
                    {{ error }}
                  </b>
                </div>
                <span class="error" *ngIf="!errorMessage; else message">
                  {{
                    status === invitationStatus.Canceled
                      ? t('Your invitation has been canceled.')
                      : t('Your invitation has expired.')
                  }}
                </span>
                <span *ngIf="successResendText" class="successResendText">{{
                  successResendText
                }}</span>

                <ng-template #message>
                  <span class="error">{{ t(errorMessage) }}</span>
                </ng-template>
                <p *ngIf="status === invitationStatus.Canceled">
                  {{ t('Please contact your coach') }}.
                </p>
              </mat-card-content>
            </div>
            <!-- <button class="resend-btn" mat-raised-button (click)="resend()">
        Create Account
      </button> -->

            <div *ngIf="isCustom; else isPremium">
              <p *ngIf="isCustom" class="message">
                {{
                  t(
                    'Your invitation has expired. Please ask your coach for a new invite.'
                  )
                }}
              </p>
            </div>

            <ng-template #isPremium>
              <button
                *ngIf="status !== invitationStatus.Canceled && !errorMessage"
                mat-flat-button
                type="button"
                (click)="resend()"
                [ngClass]="isMobile ? 'submit-btn' : 'submit-btn big-btn '"
              >
                {{ t('Resend the invitation') }}
              </button>
            </ng-template>
          </div>
        </div>
        <div class="grid-item" *ngIf="!errorMessage">
          <p
            [ngClass]="isMobile ? 'text' : 'text big-text '"
            *ngIf="status !== invitationStatus.Canceled"
          >
            {{
              t(
                'Did not receive the email? Check your spam folder, or try another email
            address.'
              )
            }}
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
