import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  InvitationService,
  InvitationStatus,
} from '@goatsports/authority/data-access';
import { TranslationsService } from '@goatsports/shared/util';
import { map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'goatsports-invitation-error',
  templateUrl: './invitation-error.component.html',
  styleUrls: ['./invitation-error.component.scss'],
})
export class InvitationErrorComponent implements OnInit {
  error!: string;
  isMobile = false;
  language$ = this.translationsService.selectedLanguage$;
  successResendText!: string;
  readonly invitationStatus = InvitationStatus;
  @Input() status!: InvitationStatus;
  @Input() errorMessage!: string;
  @Input() isCustom!: boolean;
  constructor(
    private invitationService: InvitationService,
    private route: ActivatedRoute,
    private translationsService: TranslationsService
  ) {}

  resend() {
    this.route.queryParamMap
      .pipe(
        map((params) => {
          return params.get('inviteId') as string;
        }),
        switchMap((inviteId) =>
          this.invitationService.invitationIdPut(inviteId).pipe(
            tap(() => {
              this.successResendText =
                'Another invitation is sent, check your inbox.';
            })
          )
        )
      )
      .subscribe();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      this.isMobile = true;
    } else {
      // false for not mobile device
      this.isMobile = false;
    }
  }
}
