import { Component } from '@angular/core';
import {
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'goatsports-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent {
  isMobile = this.deviceService.isMobile();
  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.translationsService.changeLanguage(language);
    })
  );

  constructor(
    private translationsService: TranslationsService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService
  ) {}
}
