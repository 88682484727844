import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailConfirmationService } from '@goatsports/authority/data-access';
import {
  BaseComponent,
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'goatsports-email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.scss'],
})
export class EmailConfirmedComponent
  extends BaseComponent
  implements OnDestroy
{
  language!: string;
  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      if (params) {
        const langParam = params.get('language');
        const langFromStorage = localStorage.getItem(
          StorageKeyConstants.LANGUAGE_KEY
        );
        const language = langParam
          ? langParam
          : langFromStorage
          ? langFromStorage
          : 'en';
        this.language = language;
        this.translationsService.changeLanguage(language);
      }
    }),
    switchMap((params) =>
      this.emailConfirmationService.emailConfirmationPut({
        email: params.get('email') ?? '',
        token: params.get('token') ?? '',
      })
    )
  );

  constructor(
    private emailConfirmationService: EmailConfirmationService,
    private route: ActivatedRoute,
    private translationsService: TranslationsService,
    private deviceService: DeviceDetectorService
  ) {
    super();
  }

  redirectToLogin() {
    if (this.deviceService.isMobile()) {
      location.href = `goatsports://email-confirmed?language=${this.language}`;
    } else {
      location.href = environment.coachPortalUrl;
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
