<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async }">
      <div class="grid-container">
        <div class="grid-item">
          <img
            src="../../assets/icons/goat-sports-logo.png"
            [ngClass]="isMobile ? 'small-logo' : 'big-logo'"
          />
        </div>
        <div class="grid-item">
          <div [ngClass]="isMobile ? 'container' : 'container fullHeight'">
            <mat-card-content>
              <div>
                @if (text === 'Your email has been confirmed!') {
                  <img
                    [ngClass]="
                      isMobile ? 'checkmark-icon' : 'checkmark-big-icon'
                    "
                    src="../../assets/icons/checkmark-circle-outline.svg"
                  />
                } @else {
                  <img
                    [ngClass]="
                      isMobile ? 'checkmark-icon' : 'checkmark-big-icon'
                    "
                    src="../../assets/icons/close-icon.svg"
                  />
                }

                <b>
                  {{ t(text) }}
                </b>
              </div>
              @if (text === 'Your email has been confirmed!') {
                <p *ngIf="isMobile">
                  <u class="download-app" (click)="redirectToDownload()">{{
                    t('Download')
                  }}</u>

                  <span> {{ t(' the app or ') }}</span>

                  <u class="download-app" (click)="redirectToLogin()">{{
                    t('LOG IN')
                  }}</u
                  >.
                </p>
                <p *ngIf="!isMobile">
                  {{ t('Switch to the app to') }}
                  <u class="download-app">{{ t('LOG IN') }}</u
                  >.
                </p>

                <div class="desktop-icons-and-text" *ngIf="!isMobile">
                  <p>{{ t('Don’t have the app? Get it in the store') }}.</p>
                  <div class="scan-icons">
                    <!-- QR code  -->
                    <img
                      style="cursor: pointer"
                      height="38px"
                      src="../../assets/icons/app-store-icon.svg"
                      alt=""
                      (click)="
                        navigateToStore('https://goatsports.app/appstore')
                      "
                    />
                    <img
                      style="cursor: pointer"
                      height="38px"
                      src="../../assets/icons/google-play-icon.svg"
                      alt=""
                      (click)="
                        navigateToStore('https://goatsports.app/googleplay')
                      "
                    />
                  </div>
                </div>
              }
            </mat-card-content>
          </div>
        </div>
        <div class="grid-item footer-image" *ngIf="isMobile">
          <div>
            <img
              height="38px"
              src="../../assets/icons/app-store-icon.svg"
              alt=""
              (click)="navigateToStore('https://goatsports.app/appstore')"
            />
            <img
              height="38px"
              src="../../assets/icons/google-play-icon.svg"
              alt=""
              (click)="navigateToStore('https://goatsports.app/googleplay')"
            />
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>
