import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent, TranslationsService } from '@goatsports/shared/util';
import { environment } from '../../environments/environment';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'goatsports-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class SignOutComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  language$ = this.translationsService.selectedLanguage$;

  constructor(
    private translocoService: TranslocoService,
    private translationsService: TranslationsService
  ) {
    super();
  }

  ngOnInit() {
    location.href = environment.coachPortalUrl;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }
}
