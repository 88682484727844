import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBaseComponent,
  RegexPatterns,
  TranslationsService,
} from '@goatsports/shared/util';
import { finalize, tap } from 'rxjs';
import {
  AuthenticationService,
  SignInCommand,
} from '@goatsports/authority/data-access';
import { RouteConstants } from '../shared/constants/route.constants';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'goatsports-invitation-login',
  templateUrl: './invitation-login.component.html',
  styleUrls: ['./invitation-login.component.scss'],
})
export class InvitationLoginComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  inviteId!: string;
  returnUrl = '';
  isPasswordVisible = false;
  activeLanguage!: string;
  language$ = this.translationsService.selectedLanguage$.pipe(
    tap((x) => {
      this.activeLanguage = x;
    })
  );

  override form = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(RegexPatterns.PASSWORD_STRENGTH_REGEX),
      ],
    ],
  });

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private translationsService: TranslationsService
  ) {
    super();
  }

  override ngOnInit() {
    this.route.queryParamMap
      .pipe(
        tap((params) => {
          this.returnUrl = params.get('ReturnUrl') as string;
          const url = new URL(this.returnUrl);
          this.inviteId = url.searchParams.get('inviteId') as string;
        })
      )
      .subscribe();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  navigateToCreateAcc() {
    this.router.navigate(['/signup']);
  }

  submit() {
    if (this.isWaiting) return;
    if (this.checkFormValidity()) return;

    this.isWaiting = true;
    const signInCommand: SignInCommand = {
      email: this.form.value.email as string,
      password: this.form.value.password as string,
      rememberMe: false,
      returnUrl: this.returnUrl,
      inviteId: this.inviteId,
    };

    this.authenticationService
      .authenticationSignInPost(signInCommand)
      .pipe(
        tap(() => {
          location.href = this.returnUrl;
        }),
        finalize(() => (this.isWaiting = false))
      )
      .subscribe();
  }

  redirectForgotPassword() {
    this.router.navigate([`/${RouteConstants.FORGOT_PASSWORD_ROUTE}`], {
      queryParams: { ReturnUrl: this.returnUrl, lang: this.activeLanguage },
    });
  }

  //REVIEW: move to base class?
  @HostListener('document:keydown.enter', ['$event']) onEnterHandler(): void {
    this.submit();
  }
}
