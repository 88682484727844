<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async } as vm">
      <div class="grid-container">
        <div class="grid-item">
          <goatsports-header imgSrc="../../assets/icons/goat-sports-logo.png">
            <b>{{ t('Create your new password') }}.</b>
            <p>{{ t('Confirm it to be sure you remembered it') }}.</p>
          </goatsports-header>
        </div>
        <div class="grid-item">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <mat-label
              >{{ t('New password') }}
              <span class="required"> *</span></mat-label
            >
            <mat-form-field
              style="margin-bottom: 36px"
              [ngClass]="{
                'incorrect-password-format':
                  form.controls.newPassword.errors?.pattern && showValidation
              }"
            >
              <input
                matInput
                formControlName="newPassword"
                [type]="isNewPasswordVisible ? 'text' : 'password'"
                [placeholder]="t('Enter your password')"
                required
              />
              <button
                mat-icon-button
                matSuffix
                (click)="
                  $event.preventDefault();
                  isNewPasswordVisible = !isNewPasswordVisible
                "
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="isNewPasswordVisible"
                type="button"
              >
                <mat-icon>{{
                  isNewPasswordVisible ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
              <mat-hint>{{
                t(
                  'Password must be at least 8 characters long with a combination of uppercase letters, lowercase letters, numbers, and symbols'
                )
              }}</mat-hint>
              <mat-error
                *ngIf="
                  form.controls.newPassword.errors?.required && showValidation
                "
              >
                {{ t('New password is required') }}.</mat-error
              >
              <mat-error
                *ngIf="
                  form.controls.newPassword.errors?.pattern && showValidation
                "
              >
                {{ t(passwordPatternError) }}.</mat-error
              >
            </mat-form-field>

            <mat-label
              >{{ t('Confirm new password') }}
              <span class="required"> *</span></mat-label
            >

            <mat-form-field>
              <input
                matInput
                formControlName="confirmNewPassword"
                [type]="isConfirmPasswordVisible ? 'text' : 'password'"
                [placeholder]="t('Enter your new password again')"
                required
              />
              <button
                mat-icon-button
                matSuffix
                (click)="
                  $event.preventDefault();
                  isConfirmPasswordVisible = !isConfirmPasswordVisible
                "
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="isConfirmPasswordVisible"
                type="button"
              >
                <mat-icon>{{
                  isConfirmPasswordVisible ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
              <mat-error
                *ngIf="
                  form.controls.confirmNewPassword.errors?.required &&
                  showValidation
                "
              >
                {{ t('Confirm new password is required') }}.</mat-error
              >
            </mat-form-field>
            <div
              class="passwords-match-container"
              *ngIf="form.errors?.passwordMatch && showValidation"
            >
              <mat-error> {{ t('Both passwords must match') }}. </mat-error>
            </div>

            <goatsports-validation-errors></goatsports-validation-errors>
            <goatsports-error-messages></goatsports-error-messages>

            <goatsports-submit-btn
              [text]="t('Reset Password')"
              [isWaiting]="(authorityLoadingService.isWaiting$ | async)!"
              (submitBtnClicked)="submit()"
            ></goatsports-submit-btn>
          </form>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>
