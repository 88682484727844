import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  SignUpCommand,
} from '@goatsports/authority/data-access';
import {
  FormBaseComponent,
  RegexPatterns,
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { tap } from 'rxjs';
import { AppValidators } from '../shared/app.validators';
import { AuthorityLoadingService } from '../shared/services/authority-loading.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';

@Component({
  selector: 'goatsports-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [AuthorityLoadingService],
})
export class SignUpComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  readonly passwordPatternError = `Password must be at least 8 characters long with a combination of uppercase letters, lowercase letters, numbers, and symbols `;
  readonly termsAndConditions = 'https://goatsports.app/terms-and-conditions/';

  isPasswordHidden = true;
  isConfirmPasswordHidden = true;
  email!: string;
  inviteId!: string;
  isMobile = this.deviceService.isMobile();
  isLogin!: boolean;
  language!: string;

  override form = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: [
      '',
      [Validators.required, Validators.pattern(RegexPatterns.EMAIL_REGEX)],
    ],
    passwords: this.fb.group(
      {
        newPassword: this.fb.control('', [
          Validators.required,
          Validators.pattern(RegexPatterns.PASSWORD_STRENGTH_REGEX),
        ]),
        confirmNewPassword: this.fb.control('', [Validators.required]),
      },
      { validators: AppValidators.passwordMatch }
    ),
  });

  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      this.inviteId = params.get('inviteId') ?? '';
      this.email = params.get('email') ?? '';
      this.form.controls.email.patchValue(this.email ?? '');
      this.form.controls.firstName.patchValue(params.get('firstName') ?? '');
      this.form.controls.lastName.patchValue(params.get('lastName') ?? '');
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.language = language;
      this.translationsService.changeLanguage(language);
    })
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public authorityLoadingService: AuthorityLoadingService,
    private translationsService: TranslationsService,
    private deviceService: DeviceDetectorService
  ) {
    super();
  }

  override ngOnInit() {
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    this.isLogin = params.get('isLogin') === 'true';
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  navigateBack() {
    location.href = `goatsports://login?language=${this.language}`;
  }

  navigateToTerms() {
    location.href = this.termsAndConditions;
  }

  submit() {
    if (this.checkFormValidity()) return;
    const signUpCommand: SignUpCommand = {
      firstName: this.form.value.firstName as string,
      lastName: this.form.value.lastName as string,
      email: this.form.value.email as string,
      password: this.form.value.passwords?.newPassword as string,
      confirmPassword: this.form.value.passwords?.confirmNewPassword as string,
      inviteId: this.inviteId,
    };
    this.authorityLoadingService
      .showWaitingIndicator(
        this.authenticationService.authenticationSignUpPost(signUpCommand).pipe(
          tap(() => {
            this.router.navigate(['/email-check'], {
              queryParams: { email: this.email, language: this.language },
            });
          })
        )
      )
      .subscribe();
  }
}
