<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ language: language$ | async } as vm">
      <div class="grid-container">
        <goatsports-header imgSrc="../../assets/icons/goat-sports-logo.png">
          <b>{{ t('Forgot your password') }}?</b>
          <p>
            {{
              t("Don't worry, you can reset it by entering your email address")
            }}.
          </p>
        </goatsports-header>
        <div class="grid-item">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <mat-label
              >{{ t('Email') }} <span class="required"> *</span></mat-label
            >
            <mat-form-field>
              <input matInput formControlName="email" type="email" />
              <mat-error
                *ngIf="form.controls.email.errors?.required && showValidation"
                >{{ t('Email is required') }}.</mat-error
              >
              <mat-error
                *ngIf="form.controls.email.errors?.email && showValidation"
              >
                {{ t('Email is not in a valid format') }}.</mat-error
              >
            </mat-form-field>

            <goatsports-validation-errors></goatsports-validation-errors>
            <goatsports-error-messages></goatsports-error-messages>

            <goatsports-submit-btn
              [text]="t('Submit')"
              [isWaiting]="(authorityLoadingService.isWaiting$ | async)!"
              (submitBtnClicked)="submit()"
            ></goatsports-submit-btn>
          </form>
        </div>
        <div class="sign-in-button">
          <button
            mat-button
            [disabled]="authorityLoadingService.isWaiting$ | async"
            (click)="backToHome()"
            color="primary"
          >
            <u>{{ t('Cancel' | uppercase) }}</u>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>
