<main [class.menu-open]="isMenuOpen">
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async } as vm">
      <div class="grid-container">
        <div class="signin-header">
          <div style="width: 64px"></div>
          <goatsports-header imgSrc="../../assets/icons/goat-sports-logo.png">
            <b>{{ t('Welcome back to GOAT Sports') }}!</b>
          </goatsports-header>
          <div style="display: flex; justify-content: flex-end">
            <button
              mat-button
              [matMenuTriggerFor]="menu"
              (menuOpened)="menuOpened()"
              (menuClosed)="menuClosed()"
              #menuTrigger="matMenuTrigger"
              [ngClass]="isMenuOpen ? 'opacity-element' : ''"
            >
              <img [src]="flagPath" width="32" />
            </button>
            <mat-menu #menu="matMenu">
              <span class="chose-language">{{ t('Choose Language') }}</span>
              <hr />
              <button
                class="language-btn"
                *ngFor="let language of availableLanguages"
                mat-menu-item
                (click)="changeLanguage(language.id)"
              >
                {{ t(language.label) }}
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="grid-item">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <mat-label
              >{{ t('Email') }} <span class="required"> *</span></mat-label
            >
            <mat-form-field>
              <input
                matInput
                autocapitalize="none"
                formControlName="email"
                type="email"
                [placeholder]="t('mail@example.com')"
              />
              <mat-error
                *ngIf="form.controls.email.errors?.required && showValidation"
                >{{ t('Email is required') }}.</mat-error
              >
              <mat-error
                *ngIf="form.controls.email.errors?.pattern && showValidation"
              >
                {{ t('Email is not in a valid format') }}.</mat-error
              >
            </mat-form-field>
            <mat-label
              >{{ t('Password') }} <span class="required"> *</span></mat-label
            >

            <mat-form-field>
              <input
                matInput
                [type]="isPasswordVisible ? 'text' : 'password'"
                autocomplete="on"
                formControlName="password"
                [placeholder]="t('Enter your password')"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="
                  $event.preventDefault();
                  isPasswordVisible = !isPasswordVisible
                "
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="isPasswordVisible"
              >
                <mat-icon>{{
                  isPasswordVisible ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
              <mat-error
                *ngIf="
                  form.controls.password.errors?.required && showValidation
                "
                >{{ t('Password is required') }}.</mat-error
              >
            </mat-form-field>

            <div class="forgot-password-wrapper">
              <button
                mat-button
                [disabled]="authorityLoadingService.isWaiting$ | async"
                (click)="redirectForgotPassword()"
              >
                {{ t('Forgot Password') }}?
              </button>
            </div>
            <goatsports-validation-errors></goatsports-validation-errors>
            <goatsports-error-messages
              class="error-messages"
            ></goatsports-error-messages>
            <goatsports-submit-btn
              [text]="t('Log In')"
              [isWaiting]="(authorityLoadingService.isWaiting$ | async)!"
              (submitBtnClicked)="submit()"
            ></goatsports-submit-btn>
          </form>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>
