import { Component } from '@angular/core';
import {
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'goatsports-password-confirmed',
  templateUrl: './password-confirmed.component.html',
  styleUrls: ['./password-confirmed.component.scss'],
})
export class PasswordConfirmedComponent {
  language!: string;
  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.language = language;
      this.translationsService.changeLanguage(language);
    })
  );

  constructor(
    private route: ActivatedRoute,
    private translationsService: TranslationsService,
    private deviceService: DeviceDetectorService
  ) {}

  redirectToLogin() {
    if (this.deviceService.isMobile()) {
      location.href = `goatsports://mobile-password-changed?language=${this.language}`;
    } else {
      location.href = `${environment.coachPortalUrl}/profile?language=${this.language}&isChangePassword=true`;
    }
  }
}
