import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  InvitationService,
  InvitationStatus,
  RoleType,
} from '@goatsports/authority/data-access';
import {
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';

@Component({
  selector: 'goatsports-accept-invitation-organization-admin',
  templateUrl: './accept-invitation-organization-admin.component.html',
  styleUrls: ['./accept-invitation-organization-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptInvitationOrganizationAdminComponent {
  readonly InvitationStatus = InvitationStatus;
  readonly termsAndConditions = 'https://goatsports.app/terms-and-conditions/';
  isMobile = this.deviceService.isMobile();
  inviteId!: string;
  email!: string;
  role!: RoleType;
  firstName!: string;
  lastName!: string;
  language!: string;

  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      this.firstName = params.get('firstName') ?? '';
      this.lastName = params.get('lastName') ?? '';
      this.inviteId = params.get('inviteId') ?? '';
      this.email = params.get('email') ?? '';
      this.role = params.get('role') as RoleType;
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.translationsService.changeLanguage(language);
    }),
    switchMap(() => this.invitationService.invitationStatusGet(this.inviteId)),
    map((res) => {
      return { status: res.status as InvitationStatus };
    })
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invitationService: InvitationService,
    private translationsService: TranslationsService,
    private deviceService: DeviceDetectorService
  ) {}

  redirectToSignUp() {
    this.router.navigate(['/signup'], {
      queryParams: {
        inviteId: this.inviteId,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        language: this.language,
      },
    });
  }

  redirectToSignIn() {
    if (
      this.role === RoleType.CustomClient ||
      this.role === RoleType.PremiumClient
    ) {
      location.href = `${environment.clientAppUrl}accept-invitation?language=${this.language}`;
    } else {
      const url = new URL(`${environment.coachPortalUrl}/login`);
      url.searchParams.append('inviteId', this.inviteId);
      url.searchParams.append('language', this.language);
      location.href = url.href;
    }
  }

  navigateToTerms() {
    location.href = this.termsAndConditions;
  }
}
