import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EmailConfirmedComponent } from './email-confirmed/email-confirmed.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AcceptInvitationPremiumComponent } from './accept-invitation-premium/accept-invitation-premium.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { MailCheckComponent } from './mail-check/mail-check.component';
import { PasswordConfirmedComponent } from './password-confirmed/password-confirmed.component';
import { CreateAccountCheckEmailComponent } from './create-account-check-email/create-account-check-email.component';
import { InvitationErrorComponent } from './invitation-error/invitation-error.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { AcceptedEmailComponent } from './accepted-email/accepted-email.component';
import { InvitationLoginComponent } from './invitation-login/invitation-login.component';

import { InvitationEmailConfirmationComponent } from './invitation-email-confirmation/invitation-email-confirmation.component';
import { AcceptInvitationCoachComponent } from './accept-invitation-coach/accept-invitation-coach.component';
import { ConfirmEmailCoachComponent } from './confirm-email-coach/confirm-email-coach.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { AcceptInvitationCustomComponent } from './accept-invitation-custom/accept-invitation-custom.component';
import { AcceptInvitationOrganizationAdminComponent } from './accept-invitation-organization-admin/accept-invitation-organization-admin.component';

const routes: Routes = [
  { path: 'signin', component: SignInComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'signout', component: SignOutComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: 'accept-invitation-premium',
    component: AcceptInvitationPremiumComponent,
  },
  {
    path: 'accept-invitation-custom',
    component: AcceptInvitationCustomComponent,
  },
  { path: 'password-recovery', component: PasswordRecoveryComponent },
  {
    path: 'payment-success',
    loadChildren: () =>
      import('./payment-success/payment-success.module').then(
        (m) => m.PaymentSuccessModule
      ),
  },
  { path: 'email-check', component: MailCheckComponent },
  { path: 'invitation-login', component: InvitationLoginComponent },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },

  {
    path: 'change-email',
    loadChildren: () =>
      import('./change-email/change-email.module').then(
        (m) => m.ChangeEmailModule
      ),
  },

  {
    path: 'confirm-change-email',
    loadChildren: () =>
      import('./confirm-change-email/confirm-change-email.module').then(
        (m) => m.ConfirmChangeEmailModule
      ),
  },

  {
    path: 'mail-change-check',
    loadChildren: () =>
      import('./mail-change-check/mail-change-check.module').then(
        (m) => m.MailChangeCheckModule
      ),
  },
  {
    path: 'validate-email-token',
    loadChildren: () =>
      import('./validate-email-token/validate-email-token.module').then(
        (m) => m.ValidateEmailTokenModule
      ),
  },
  {
    path: 'email-confirmed',
    component: EmailConfirmedComponent,
  },

  {
    path: 'password-confirmed',
    component: PasswordConfirmedComponent,
  },
  {
    path: 'create-account-check-email',
    component: CreateAccountCheckEmailComponent,
  },
  {
    path: 'invitation-error',
    component: InvitationErrorComponent,
  },
  {
    path: 'confirm-email',
    component: ConfirmEmailComponent,
  },
  {
    path: 'accepted-email',
    component: AcceptedEmailComponent,
  },
  {
    path: 'invitation-email-confirmation',
    component: InvitationEmailConfirmationComponent,
  },
  {
    path: 'accept-invitation-coach',
    component: AcceptInvitationCoachComponent,
  },

  {
    path: 'accept-invitation-organization-admin',
    component: AcceptInvitationOrganizationAdminComponent,
  },

  {
    path: 'confirm-email-coach',
    component: ConfirmEmailCoachComponent,
  },
  {
    path: 'confirm-email-organization-admin',
    component: ConfirmEmailCoachComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
