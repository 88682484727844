<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async } as vm">
      <div
        *ngIf="vm.data?.status === InvitationStatus.Pending"
        class="buttons-container"
      >
        <img
          src="../../assets/icons/goat-sports-logo.png"
          [ngClass]="isMobile ? 'small-logo' : 'big-logo'"
        />

        <div class="message">
          <b>{{ t('Welcome to the GOAT Sports') }}!</b>
          <p>{{ t('Create an account to start using Goat Sports App') }}.</p>
        </div>

        <button
          [ngClass]="isMobile ? 'isMobile-button' : 'button'"
          mat-raised-button
          (click)="redirectToSignUp()"
        >
          {{ t('Create Account') }}
        </button>
        <p class="text-white">
          {{ t('Accept the invitation with existing account?') }}
          <u class="login" (click)="redirectToSignIn()">{{ t('LOG IN') }}</u>
        </p>
      </div>
      <ng-container *ngIf="vm.data?.status; else loading">
        <ng-container
          *ngIf="vm.data?.status !== InvitationStatus.Pending || errorMessage"
        >
          <goatsports-invitation-error
            [isCustom]="true"
            [status]="vm.data?.status!"
            [errorMessage]="errorMessage"
          ></goatsports-invitation-error>
        </ng-container>
      </ng-container>

      <ng-template #loading>
        <mat-spinner></mat-spinner>
      </ng-template>
    </ng-container>
  </ng-container>
</main>
