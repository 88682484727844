<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async }">
      <div class="grid-container">
        <div class="grid-item">
          <img src="../../assets/icons/goat-sports-logo.png" [ngClass]="isMobile ? 'small-logo' : 'big-logo'" />
        </div>
        <div class="grid-item">
          <div [ngClass]="isMobile ? 'container' : 'container fullHeight'">
            <mat-card-content>
              <div>
                <!-- <mat-icon class="material-icons material-icons-outlined">
              mail</mat-icon
            > -->
                <img [ngClass]="isMobile ? 'checkmark-icon' : 'checkmark-big-icon'"
                  src="../../assets/icons/mail-unread-outline.svg" />

                <b>
                  {{ t('Check your email.') }}
                </b>
              </div>

              <p>
                {{
                t(
                'We have sent a confirmation email, follow the steps to create your account'
                )
                }}.
              </p>
            </mat-card-content>
          </div>
        </div>
        <div class="grid-item">
          <p class="text">
            {{
            t(
            'Didn’t receive the email? Check your spam folder, or try another email address'
            )
            }}.
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>