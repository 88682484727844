import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { EmailConfirmationService } from '@goatsports/authority/data-access';
import { catchError, switchMap, tap, throwError } from 'rxjs';
import {
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'goatsports-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent {
  title!: string;
  detail!: string;
  returnUrl!: string;
  email!: string;
  language!: string;
  isMobile = this.deviceService.isMobile();

  text = '';
  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      this.returnUrl = params.get('returnUrl') ?? '';
      this.title = params.get('title') ?? '';
      this.detail = params.get('detail') ?? '';
      this.email = params.get('email') ?? '';
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY,
      );
      const language = langParam
        ? langParam
        : langFromStorage
          ? langFromStorage
          : 'en';
      this.language = language;
      this.translationsService.changeLanguage(language);
    }),
    switchMap((params) => {
      return this.emailConfirmationService
        .emailConfirmationPut({
          email: this.email,
          token: params.get('token') ?? '',
        })
        .pipe(
          tap(() => {
            this.text = 'Your email has been confirmed!';
          }),
          catchError((error) => {
            this.text = error.error.errors.token[0];
            return throwError(() => error);
          }),
        );
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private emailConfirmationService: EmailConfirmationService,
    private translationsService: TranslationsService,
    private deviceService: DeviceDetectorService,
  ) {}

  redirectToLogin() {
    if (this.deviceService.isMobile()) {
      location.href = `goatsports://email-confirmed?language=${this.language}`;
    } else {
      location.href = `${environment.coachPortalUrl}/?logOut=true`;
    }
  }

  redirectToDownload() {
    location.href = 'https://goatsports.app/download';
  }

  navigateToStore(route: string) {
    location.href = route;
  }
}
