import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, throwError } from 'rxjs';
import { StorageKeyConstants } from '@goatsports/shared/util';
import { ErrorService } from '../services/error.service';

@Injectable()
export class ServerHttpInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const language = localStorage.getItem(StorageKeyConstants.LANGUAGE_KEY);
    if (language) {
      request = request.clone({
        headers: request.headers.set('language', language),
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorService.clearServerErrors();
        switch (error.status) {
          case 0:
          case 401:
          case 403:
            break;
          case 400: {
            const validationErrors = [];
            for (const value of Object.values(error.error.errors)) {
              if (Array.isArray(value)) {
                validationErrors.push(...value);
              } else {
                validationErrors.push(value);
              }
            }
            this.errorService.publishValidationErrors(validationErrors);
            break;
          }

          case 404: {
            this.errorService.publishErrorMessage('Resource not found');
            break;
          }

          case 500: {
            this.errorService.publishErrorMessage(
              'An error occurred while processing your request'
            );
            break;
          }

          default: {
            const errorMessage = 'Server is not reachable, please try again';
            this.errorService.publishErrorMessage(errorMessage);
            break;
          }
        }
        return throwError(() => error);
      })
    );
  }
}
