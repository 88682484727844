<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async }">
      <div class="grid-container">
        <div class="grid-item">
          <img
            src="../../assets/icons/goat-sports-logo.png"
            width="100"
            height="50"
          />
        </div>
        <div class="grid-item">
          <div class="container">
            <mat-card-content>
              <div>
                <mat-icon class="material-icons material-icons-outlined">
                  check_circle</mat-icon
                >
                <b>
                  {{ t('Your email has been confirmed!') }}
                </b>
              </div>

              <p>
                {{ t('Please go to the ') }}
                <u class="download-app" (click)="redirectToLogin()">{{
                  t('LOGIN')
                }}</u>
                {{ ' ' + t('page') }}.
              </p>
            </mat-card-content>
          </div>
        </div>
        <div class="grid-item"></div>
      </div>
    </ng-container>
  </ng-container>
</main>
