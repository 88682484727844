import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { SignInComponent } from './sign-in/sign-in.component';
import {
  ApiModule as AuthorityApiModule,
  Configuration as AuthorityConfiguration,
} from '@goatsports/authority/data-access';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MailCheckComponent } from './mail-check/mail-check.component';
import { EmailConfirmedComponent } from './email-confirmed/email-confirmed.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import {
  DisableEnterDirective,
  TranslocoRootModule,
} from '@goatsports/shared/util';
import { ErrorMessagesComponent } from './shared/components/error-messages/error-messages.component';
import { ValidationErrorsComponent } from './shared/components/error-messages/validation-errors/validation-errors.component';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { PasswordConfirmedComponent } from './password-confirmed/password-confirmed.component';
import { CreateAccountCheckEmailComponent } from './create-account-check-email/create-account-check-email.component';
import { InvitationErrorComponent } from './invitation-error/invitation-error.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { AcceptedEmailComponent } from './accepted-email/accepted-email.component';
import { InvitationLoginComponent } from './invitation-login/invitation-login.component';
import { InvitationEmailConfirmationComponent } from './invitation-email-confirmation/invitation-email-confirmation.component';
import { AcceptInvitationCoachComponent } from './accept-invitation-coach/accept-invitation-coach.component';
import { ConfirmEmailCoachComponent } from './confirm-email-coach/confirm-email-coach.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { SubmitBtnComponent } from './shared/components/submit-btn/submit-btn.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { AppErrorStateMatcher } from './shared/app-matcher';
import { HeaderComponent } from './shared/components/header/header.component';
import { AcceptInvitationCustomComponent } from './accept-invitation-custom/accept-invitation-custom.component';
import { AcceptInvitationPremiumComponent } from './accept-invitation-premium/accept-invitation-premium.component';
import { ServerHttpInterceptor } from './shared/interceptors/server-http.interceptor';
import { ErrorService } from './shared/services/error.service';
import { AcceptInvitationOrganizationAdminComponent } from './accept-invitation-organization-admin/accept-invitation-organization-admin.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    SignOutComponent,
    MailCheckComponent,
    EmailConfirmedComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    AcceptInvitationPremiumComponent,
    PasswordConfirmedComponent,
    CreateAccountCheckEmailComponent,
    InvitationErrorComponent,
    ConfirmEmailComponent,
    AcceptedEmailComponent,
    InvitationLoginComponent,
    AcceptInvitationCustomComponent,
    InvitationEmailConfirmationComponent,
    AcceptInvitationCoachComponent,
    ConfirmEmailCoachComponent,
    PasswordRecoveryComponent,
    AcceptInvitationOrganizationAdminComponent,
  ],
  imports: [
    BrowserModule,
    TranslocoRootModule,
    FormsModule,
    MatInputModule,
    MatProgressBarModule,
    DisableEnterDirective,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatButtonModule,
    ValidationErrorsComponent,
    SubmitBtnComponent,
    HeaderComponent,
    ErrorMessagesComponent,
    AuthorityApiModule.forRoot(() => {
      return new AuthorityConfiguration({
        basePath: environment.authorityApiUrl,
        withCredentials: true,
      });
    }),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: ErrorStateMatcher, useClass: AppErrorStateMatcher },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerHttpInterceptor,
      deps: [ErrorService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
