<main>
  <ng-container *transloco="let t">
    <ng-container *ngIf="{ data: data$ | async } as vm">
      <div
        *ngIf="vm.data?.status === InvitationStatus.Pending"
        class="buttons-container"
      >
        <img
          src="../../assets/icons/goat-sports-logo.png"
          [ngClass]="isMobile ? 'small-logo' : 'big-logo'"
        />

        <div class="message">
          <b>{{ t('Welcome to the GOAT Sports') }}!</b>
          <p class="info">
            {{ t('Create an account to start using Goat Sports App') }}.
          </p>
        </div>

        <button
          [ngClass]="isMobile ? 'isMobile-button' : 'button'"
          mat-raised-button
          (click)="redirectToSignUp()"
        >
          {{ t('Create Account') }}
        </button>
        <div style="display: flex; flex-direction: column; align-items: center">
          <!-- <div class="terms-wrapper-content"> -->
          <span class="invitation-account-text"
            >{{ t('By creating an account you agree to our') }}
          </span>
          <u class="cursor" (click)="navigateToTerms()">{{
            t('Terms and Conditions')
          }}</u
          >.
          <!-- </div> -->

          <!-- <u class="cursor login" (click)="redirectToSignIn()">{{
            t('Log In') | uppercase
          }}</u> -->
        </div>

        <!-- *ngIf="role !== roleType.Coach && isMobile; else notMobile" -->

        <!-- <ng-template #notMobile>
      <div class="message">
        <p>
          Please open this page on mobile device if you want to log in with
          another account.
        </p>
      </div>
    </ng-template> -->
      </div>

      <ng-container *ngIf="vm.data?.status; else loading">
        <ng-container *ngIf="vm.data?.status !== InvitationStatus.Pending">
          <goatsports-invitation-error
            [status]="vm.data?.status!"
          ></goatsports-invitation-error>
        </ng-container>
      </ng-container>
      <ng-template #loading>
        <mat-spinner></mat-spinner>
      </ng-template>
    </ng-container>
  </ng-container>
</main>
