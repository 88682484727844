<main>
  <ng-container *transloco="let t">
    <ng-container>
      <div class="grid-container">
        <goatsports-header imgSrc="../../assets/icons/goat-sports-logo.png">
          <b>{{ t('Welcome to GOAT Sports') }}!</b>
          <p>{{ t("Let's get you started by creating your account") }}.</p>
        </goatsports-header>
        <div class="grid-item">
          <ng-container *ngIf="{ data: data$ | async } as vm">
            <form [formGroup]="form" (ngSubmit)="submit()">
              <mat-label
                >{{ t('First Name') }}
                <span class="required"> *</span></mat-label
              >

              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="firstName"
                  [placeholder]="t('Enter your First Name')"
                />
                <mat-error
                  *ngIf="
                    form.controls.firstName.errors?.['required'] &&
                    showValidation
                  "
                >
                  {{ t('First name is required') }}
                </mat-error>
              </mat-form-field>

              <mat-label
                >{{ t('Last Name') }}
                <span class="required"> *</span></mat-label
              >

              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="lastName"
                  [placeholder]="t('Enter your Last Name')"
                />
                <mat-error
                  *ngIf="
                    form.controls.lastName.errors?.['required'] &&
                    showValidation
                  "
                >
                  {{ t('Last name is required') }}
                </mat-error>
              </mat-form-field>
              <mat-label
                >{{ t('Email') }} <span class="required"> *</span></mat-label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  formControlName="email"
                  type="email"
                  [placeholder]="t('mail@example.com')"
                  [readonly]="!isLogin ? true : false"
                />
                <mat-error
                  *ngIf="
                    form.controls.email.errors?.['email'] && showValidation
                  "
                  >{{ t('Invalid email address') }}
                </mat-error>
                <mat-error
                  *ngIf="
                    form.controls.email?.errors?.['required'] && showValidation
                  "
                  >{{ t('Email is required') }}
                </mat-error>
              </mat-form-field>
              <section formGroupName="passwords" class="passwords-container">
                <mat-label
                  >{{ t('Password') }}
                  <span class="required"> *</span></mat-label
                >

                <mat-form-field
                  style="margin-bottom: 36px"
                  [ngClass]="{
                    'incorrect-password-format':
                      form.controls.passwords.controls.newPassword.errors
                        ?.pattern && showValidation,
                  }"
                  class="password"
                  appearance="outline"
                >
                  <input
                    matInput
                    [type]="isPasswordHidden ? 'password' : 'text'"
                    formControlName="newPassword"
                    maxlength="25"
                    [placeholder]="t('Enter your password')"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="
                      $event.preventDefault();
                      isPasswordHidden = !isPasswordHidden
                    "
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="isPasswordHidden"
                  >
                    <mat-icon>{{
                      isPasswordHidden ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
                  </button>
                  <mat-error
                    *ngIf="
                      form.controls.passwords?.controls?.newPassword?.errors
                        ?.required && showValidation
                    "
                    >{{ t('Password is required') }}.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      form.controls.passwords?.controls?.newPassword?.errors
                        ?.pattern && showValidation
                    "
                  >
                    {{ t(passwordPatternError) }}.</mat-error
                  >
                  <mat-hint>{{
                    t(
                      'Password must be at least 8 characters long with a combination of uppercase letters, lowercase letters, numbers, and symbols'
                    )
                  }}</mat-hint>
                </mat-form-field>
                <mat-label
                  >{{ t('Confirm password') }}
                  <span class="required"> *</span></mat-label
                >
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [type]="isConfirmPasswordHidden ? 'password' : 'text'"
                    formControlName="confirmNewPassword"
                    maxlength="25"
                    [placeholder]="t('Enter your password again')"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="
                      $event.preventDefault();
                      isConfirmPasswordHidden = !isConfirmPasswordHidden
                    "
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="isConfirmPasswordHidden"
                  >
                    <mat-icon>{{
                      isConfirmPasswordHidden ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
                  </button>
                  <mat-error
                    *ngIf="
                      form.controls.passwords?.controls?.confirmNewPassword
                        ?.errors?.required && showValidation
                    "
                    >{{ t('Confirm password is required') }}.</mat-error
                  >
                </mat-form-field>
                <div
                  class="passwords-match-container"
                  *ngIf="
                    form.controls.passwords.errors?.passwordMatch &&
                    showValidation
                  "
                >
                  <mat-error> {{ t('Both passwords must match') }}. </mat-error>
                </div>
              </section>

              <goatsports-validation-errors></goatsports-validation-errors>
              <goatsports-error-messages></goatsports-error-messages>

              <goatsports-submit-btn
                [text]="t('Create Account')"
                [isWaiting]="(authorityLoadingService.isWaiting$ | async)!"
                (submitBtnClicked)="submit()"
              ></goatsports-submit-btn>

              <div class="go-back-button" *ngIf="isMobile">
                <span>{{ t('Already have an account') }}?</span>
                <button
                  mat-button
                  (click)="navigateBack()"
                  class="log-btn"
                  [disabled]="authorityLoadingService.isWaiting$ | async"
                >
                  {{ t('Log in' | uppercase) }}
                </button>
              </div>
            </form>
          </ng-container>
        </div>
        <div
          [ngClass]="
            isMobile
              ? 'grid-item terms-wrapper'
              : 'terms-wrapper mobile-terms-wrapper'
          "
        >
          <p>
            {{ t('By creating an account you agree to our') }}
            <u (click)="navigateToTerms()">{{ t('Terms and Conditions') }}.</u>
          </p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</main>
