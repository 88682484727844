import { Component } from '@angular/core';
import {
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'goatsports-create-account-check-email',
  templateUrl: './create-account-check-email.component.html',
  styleUrls: ['./create-account-check-email.component.scss'],
})
export class CreateAccountCheckEmailComponent {
  isMobile = this.deviceService.isMobile();

  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.translationsService.changeLanguage(language);
    })
  );

  constructor(
    private route: ActivatedRoute,
    private translationsService: TranslationsService,
    private deviceService: DeviceDetectorService
  ) {}
}
