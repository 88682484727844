import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '@goatsports/authority/data-access';
import {
  FormBaseComponent,
  RegexPatterns,
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { tap } from 'rxjs';
import { AppValidators } from '../shared/app.validators';
import { RouteConstants } from '../shared/constants/route.constants';
import { AuthorityLoadingService } from '../shared/services/authority-loading.service';

@Component({
  selector: 'goatsports-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [AuthorityLoadingService],
})
export class ResetPasswordComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  readonly passwordPatternError = `Password needs to have at least one lower case letter, one upper case letter, one number and one special character`;

  isNewPasswordVisible = false;
  isConfirmPasswordVisible = false;
  email!: string;
  confirmationToken!: string;
  language!: string;

  override form = this.fb.group(
    {
      newPassword: this.fb.nonNullable.control('', [
        Validators.required,
        Validators.pattern(RegexPatterns.PASSWORD_STRENGTH_REGEX),
      ]),
      confirmNewPassword: this.fb.nonNullable.control('', [
        Validators.required,
      ]),
    },
    { validators: AppValidators.passwordMatch }
  );

  data$ = this.route.queryParamMap.pipe(
    tap((params) => {
      this.confirmationToken = params.get('token') ?? '';
      this.email = params.get('email') ?? '';
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.language = language;
      this.translationsService.changeLanguage(language);
    })
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    public authorityLoadingService: AuthorityLoadingService,
    private translationsService: TranslationsService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  navigateBack() {
    //TODO: add navigation
  }

  submit() {
    if (this.checkFormValidity()) return;
    const command = {
      email: this.email,
      token: this.confirmationToken,
      password: this.form.value.newPassword,
    };
    this.authorityLoadingService
      .showWaitingIndicator(
        this.passwordService.passwordResetPost(command).pipe(
          tap(() => {
            this.router.navigate(
              [`/${RouteConstants.PASSWORD_CONFIRM_ROUTE}`],
              {
                queryParams: {
                  title: 'Reset password',
                  detail: 'You have successfully reset your password.',
                  language: this.language,
                  email: this.email,
                  token: this.confirmationToken,
                },
              }
            );
          })
        )
      )
      .subscribe();
  }

  //REVIEW: move to base class?
  @HostListener('document:keydown.enter', ['$event']) onEnterHandler(): void {
    this.submit();
  }
}
