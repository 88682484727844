import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  SignInCommand,
} from '@goatsports/authority/data-access';
import {
  FormBaseComponent,
  Languages,
  RegexPatterns,
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { map, tap } from 'rxjs';
import { RouteConstants } from '../shared/constants/route.constants';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthorityLoadingService } from '../shared/services/authority-loading.service';

@Component({
  selector: 'goatsports-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [AuthorityLoadingService],
})
export class SignInComponent
  extends FormBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  readonly availableLanguages = Languages.availableLanguages;

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined;
  override form = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.pattern(RegexPatterns.EMAIL_REGEX)],
    ],
    password: ['', [Validators.required]],
  });
  inviteId!: string;
  returnUrl = '';
  isPasswordVisible = false;
  isMenuOpen = false;
  language = this.availableLanguages[0].id;
  flagPath = '../../assets/icons/English.svg';

  data$ = this.processQueryParams().pipe(
    map(({ activeLanguage }) => {
      this.flagPath = this.changeFlag(activeLanguage);
      return { activeLanguage };
    })
  );

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public authorityLoadingService: AuthorityLoadingService,
    private translationsService: TranslationsService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  private processQueryParams() {
    return this.route.queryParamMap.pipe(
      map((params) => {
        this.returnUrl = params.get('ReturnUrl') ?? '';
        const url = new URL(this.returnUrl);
        this.inviteId = url.searchParams.get('inviteId') ?? '';
        const langParam = url.searchParams.get('language');
        const langFromStorage = localStorage.getItem(
          StorageKeyConstants.LANGUAGE_KEY
        );
        const language = langParam
          ? langParam
          : langFromStorage
          ? langFromStorage
          : 'en';
        this.language = language;
        this.translationsService.changeLanguage(language);
        return { activeLanguage: language };
      })
    );
  }

  ngAfterViewInit() {
    if (this.menuTrigger && this.menuTrigger.menu) {
      this.menuTrigger.menu.hasBackdrop = false;
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  changeFlag(activeLanguage: string) {
    switch (activeLanguage) {
      case 'en':
        return '../../assets/flag/gbr.svg';
      case 'sv':
        return '../../assets/flag/swe.svg';
      case 'sr-Latn':
        return '../../assets/flag/srb.svg';
      case 'is':
        return '../../assets/flag/isl.svg';
      default:
        return '../../assets/flag/gbr.svg';
    }
  }

  menuOpened() {
    this.isMenuOpen = true;
  }

  menuClosed() {
    this.isMenuOpen = false;
  }

  navigateToCreateAcc() {
    this.router.navigate(['/signup']);
  }

  changeLanguage(language: string) {
    this.translationsService.changeLanguage(language);
    this.flagPath = this.changeFlag(language);
    this.language = language;
  }

  submit() {
    if (this.checkFormValidity()) return;
    const signInCommand: SignInCommand = {
      email: this.form.value.email as string,
      password: this.form.value.password as string,
      rememberMe: false,
      returnUrl: this.returnUrl,
      inviteId: this.inviteId,
    };

    this.authorityLoadingService
      .showWaitingIndicator(
        this.authenticationService.authenticationSignInPost(signInCommand).pipe(
          tap(() => {
            if (this.inviteId) {
              this.router.navigate(['/accepted-email'], {
                queryParams: {
                  inviteId: this.inviteId,
                  language: this.language,
                },
              });
            } else {
              location.href = this.returnUrl;
            }
          })
        )
      )
      .subscribe();
  }

  redirectForgotPassword() {
    this.router.navigate([`/${RouteConstants.FORGOT_PASSWORD_ROUTE}`], {
      queryParams: { language: this.language, ReturnUrl: this.returnUrl },
    });
  }

  //REVIEW: move to base class?
  @HostListener('document:keydown.enter', ['$event']) onEnterHandler(): void {
    this.submit();
  }
}
