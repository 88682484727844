import { Component, OnInit } from '@angular/core';
import { TranslationsService } from '@goatsports/shared/util';

@Component({
  selector: 'goatsports-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'authority';
  constructor(private translationsService: TranslationsService) {}

  ngOnInit() {
    this.translationsService.initializeTranslation();
  }
}
