import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '@goatsports/authority/data-access';
import {
  FormBaseComponent,
  StorageKeyConstants,
  TranslationsService,
} from '@goatsports/shared/util';
import { tap } from 'rxjs';
import { RouteConstants } from '../shared/constants/route.constants';
import { AuthorityLoadingService } from '../shared/services/authority-loading.service';

@Component({
  selector: 'goatsports-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [AuthorityLoadingService],
})
export class ForgotPasswordComponent
  extends FormBaseComponent
  implements OnInit, OnDestroy
{
  override form = this.fb.group({
    email: this.fb.nonNullable.control('', [
      Validators.required,
      Validators.email,
    ]),
  });
  returnUrl = '';
  language!: string;

  language$ = this.route.queryParamMap.pipe(
    tap((params) => {
      this.returnUrl = params.get('ReturnUrl') ?? '';
      const langParam = params.get('language');
      const langFromStorage = localStorage.getItem(
        StorageKeyConstants.LANGUAGE_KEY
      );
      const language = langParam
        ? langParam
        : langFromStorage
        ? langFromStorage
        : 'en';
      this.language = language;
      this.translationsService.changeLanguage(language);
    })
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    public authorityLoadingService: AuthorityLoadingService,
    private translationsService: TranslationsService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  submit() {
    if (this.checkFormValidity()) return;
    this.authorityLoadingService
      .showWaitingIndicator(
        this.passwordService
          .passwordForgotPost({
            email: this.form.value?.email,
            returnUrl: this.returnUrl,
          })
          .pipe(
            tap(() => {
              this.router.navigate(
                [`/${RouteConstants.PASSWORD_RECOVERY_ROUTE}`],
                {
                  queryParams: {
                    language: this.language,
                  },
                }
              );
            })
          )
      )
      .subscribe();
  }

  backToHome() {
    this.router.navigate(['/signin'], {
      queryParams: { ReturnUrl: this.returnUrl, language: this.language },
    });
  }

  //REVIEW: move to base class?
  @HostListener('document:keydown.enter', ['$event']) onEnterHandler(): void {
    this.submit();
  }
}
